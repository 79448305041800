import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import Button from "@bit/azheng.joshua-tree.button"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

var classNames = require("classnames")

class CTA extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div
        className={`columns cta-columns ${
          this.props.className ? this.props.className : ""
        }`}>
        <div className="column">
          <div className="columns">{this.props.headingColumns}</div>
          <div className="columns">
            <div className={`column is-${this.props.sideColumnIs}`}></div>
            {this.props.textColumns}
            <div className={`column is-${this.props.sideColumnIs}`}></div>
          </div>
          <div className="columns has-text-centered is-centered">
            {this.props.buttonColumns}
          </div>
        </div>
      </div>
    )
  }
}

CTA.propTypes = {
  headingColumns: PropTypes.element,
  textColumns: PropTypes.element,
  buttonColumns: PropTypes.element,
  sideColumnIs: PropTypes.number
}

export default CTA
